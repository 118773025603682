var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.categoryName
        ? _c("h4", { staticClass: "category-title" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.categoryName) +
                " " +
                _vm._s(_vm.isMailCategory ? "Services" : "- Choose Product") +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm.isLoaded && _vm.sortedProducts.length > 0
        ? _c(
            "b-list-group",
            { staticClass: "w-100 pointer product-categories-list mb-4" },
            [
              _c("selection-cards", {
                attrs: { items: _vm.sortedProducts },
                on: { chosen: _vm.route },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }